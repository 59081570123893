import React from 'react'

const Footer = () => (
  <footer
    style={{
      textAlign: `center`,
      color: `grey`,
      fontSize: `0.7em`,
      position: `absolute`,
      bottom: 0,
      width: `100%`,
    }}
  >
    <p>
      © {new Date().getFullYear()}{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.ctrlemo.com"
      >
        EMO
      </a>
      {' | '}Made with <span style={{ color: `#e25555` }}>&#9829;</span>
    </p>
  </footer>
)

export default Footer
