import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    const { siteTitle } = this.props
    return (
      <header>
        <Navbar color="light" light expand="md">
          <NavbarBrand
            href="/"
            onClick={event => {
              event.preventDefault()
              navigate('/')
            }}
          >
            {siteTitle}
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <p className="text-muted">
              The most convenient way to locate buses on Curaçao
            </p>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  href="/about"
                  onClick={event => {
                    event.preventDefault()
                    navigate('/about')
                  }}
                >
                  About
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/privacy"
                  onClick={event => {
                    event.preventDefault()
                    navigate('/privacy')
                  }}
                >
                  Privacy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/terms"
                  onClick={event => {
                    event.preventDefault()
                    navigate('/terms')
                  }}
                >
                  Terms
                </NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Options
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Option 1</DropdownItem>
                  <DropdownItem>Option 2</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
