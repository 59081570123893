// 3rd party
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import React, { Component } from 'react'

// local
import Header from './header'
import FirebaseContext from '../components/FirebaseContext'
import getFirebase from '../services/firebase'
import Footer from '../components/footer'

//css
import 'bootstrap/dist/css/bootstrap.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firebase: null,
      authenticated: false,
    }
  }

  componentDidMount() {
    const app = import('firebase/app')
    const auth = import('firebase/auth')
    const database = import('firebase/database')

    Promise.all([app, auth, database]).then(values => {
      const firebase = getFirebase(values[0])
      this.setState({ firebase })

      firebase.auth().onAuthStateChanged(user => {
        if (!user) {
          this.setState({ authenticated: false })
        } else {
          this.setState({ authenticated: true })
        }
      })

      firebase
        .auth()
        .signInAnonymously()
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code

          if (errorCode === 'auth/operation-not-allowed') {
            console.error('Anonymous sign-in is disabled')
          } else {
            console.error('Sign-in failed for some reason')
          }
        })
    })
  }

  render = () => {
    const { children } = this.props
    const { firebase } = this.state

    if (!firebase) return null

    return (
      <FirebaseContext.Provider value={firebase}>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <FirebaseContext.Provider value={firebase}>
                <Header siteTitle={data.site.siteMetadata.title} />
                <div>{children}</div>
                <Footer />
              </FirebaseContext.Provider>
            </>
          )}
        />
      </FirebaseContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
